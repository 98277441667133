import Vue from 'vue'
import { Vuelidate, validationMixin } from 'vuelidate'
import { email, maxLength, minLength, required } from 'vuelidate/lib/validators'
import {
  EMPTY_FIELD_ERROR_MESSAGE,
  MAX_LETTERS_EMAIL,
  MAX_LETTERS_NAME,
  MAX_LETTERS_ORG,
  MIN_LETTERS_ORG,
  whiteSpaceInValue,
} from '@/helpers/regex'
import EventBus from '@/mixins/EventBus'

Vue.use(Vuelidate)

export default {
  mixins: [validationMixin, EventBus],
  data() {
    return {
      // here we store just invalid values, example: { email: ['test@gmail.com', ...] }
      invalidValues: {
        firstname: [],
        lastname: [],
        companyName: [],
        email: [],
        phone: [],
        password: [],
        fio: [],
      },
      // here we store pairs value-reason, example: { 'test@gmail.com': 'domain_not_organization' }
      valuesServerErrors: {},
    }
  },
  validations() {
    const availableValidations = {
      firstname: {
        required,
        maxLength: maxLength(MAX_LETTERS_NAME),
        serverValidated: () => this.serverValidated('firstname'),
      },
      lastname: {
        required,
        maxLength: maxLength(MAX_LETTERS_NAME),
        serverValidated: () => this.serverValidated('lastname'),
      },
      email: {
        required,
        email,
        maxLength: maxLength(MAX_LETTERS_EMAIL),
        serverValidated: () => this.serverValidated('email'),
      },
      phone: {
        required,
        serverValidated: () => this.serverValidated('phone'),
      },
      password: {
        required,
        whiteSpaceInValue,
        minLength: minLength(MIN_LETTERS_ORG),
        maxLength: maxLength(MAX_LETTERS_ORG),
        serverValidated: () => this.serverValidated('password'),
      },
      companyName: {
        required,
        maxLength: maxLength(MAX_LETTERS_ORG),
        serverValidated: () => this.serverValidated('companyName'),
      },
      purpose_of_use: {
        required,
      },
      number_of_employees_select: {
        required,
      },
      fio: {
        required,
        maxLength: maxLength(MAX_LETTERS_NAME),
        serverValidated: () => this.serverValidated('fio'),
      },
      question: {
        required,
      },
      message: {
        required,
      },
      city: {
        required,
      },
      site: {
        required,
      },
      typePartners: {
        required,
      },
      user_role_select: {
        required,
      },
      direction_to_use_select: {
        required,
      },
      promo: {
        maxLength: maxLength(10),
      },
    }

    const enabled = {
      form: {},
    }

    for (const field in this.form) {
      if (availableValidations[field]) {
        enabled.form[field] = availableValidations[field]
      }
    }

    if (this.isShowNumberDropdown) {
      enabled.form.number_of_employees_select =
        availableValidations.number_of_employees_select

      if (this.isShowNumberDropdown && this.form.user_role_select) {
        enabled.form.user_role_select = availableValidations.user_role_select
      }
    } else {
      delete enabled.form.number_of_employees_select
      delete enabled.form.user_role_select
    }
    if (
      this.isShowNumberDropdown &&
      this.formName == 'main-registration-with-job'
      ) {
      enabled.form.direction_to_use_select =
        availableValidations.direction_to_use_select
    } else {
      delete enabled.form.direction_to_use_select
    }

    return enabled
  },

  methods: {
    getErrors(field) {
      switch (field) {
        case 'firstname':
          if (!this.$v.form[field].required && this.$v.$dirty) {
            return EMPTY_FIELD_ERROR_MESSAGE
          }
          if (this.$v.form[field].$model.length > MAX_LETTERS_NAME) {
            return 'Максимальное количество знаков ' + MAX_LETTERS_NAME
          }
          if (!this.$v.form[field].serverValidated) {
            return 'Проверьте правильность заполнения поля'
          }
          break
        case 'lastname':
          if (!this.$v.form[field].required && this.$v.$dirty) {
            return EMPTY_FIELD_ERROR_MESSAGE
          }
          if (this.$v.form[field].$model.length > MAX_LETTERS_NAME) {
            return 'Максимальное количество знаков ' + MAX_LETTERS_NAME
          }
          if (!this.$v.form[field].serverValidated) {
            return 'Проверьте правильность заполнения поля'
          }
          break
        case 'companyName':
          if (!this.$v.form[field].required && this.$v.$dirty) {
            return EMPTY_FIELD_ERROR_MESSAGE
          }
          if (this.$v.form[field].$model.length > MAX_LETTERS_ORG) {
            return 'Максимальное количество знаков ' + MAX_LETTERS_ORG
          }
          if (!this.$v.form[field].serverValidated) {
            return 'Проверьте правильность заполнения поля'
          }
          break
        case 'email':
          if (this.$v.form[field].$model.length > MAX_LETTERS_EMAIL) {
            return 'Максимальное количество знаков ' + MAX_LETTERS_EMAIL
          }
          if (
            (!this.$v.form[field].required || !this.$v.form[field].email) &&
            this.$v.$dirty
          ) {
            return 'Введите адрес в формате name@domen.ru'
          }
          if (!this.$v.form[field].serverValidated) {
            if (
              this.valuesServerErrors?.[this.form[field]] ===
              'domain_not_organization'
            ) {
              return 'Пожалуйста, оставьте заявку с корпоративной почты'
            }

            return 'Проверьте правильность заполнения поля'
          }
          break
        case 'password':
          if (!this.$v.form[field].required && this.$v.$dirty) {
            return 'Введите не менее 8 символов без пробелов'
          }
          if (!this.$v.form[field].whiteSpaceInValue && this.$v.$dirty) {
            return 'Введите не менее 8 символов без пробелов'
          }
          if (
            this.$v.form[field].$model.length < MIN_LETTERS_ORG &&
            this.$v.form[field].$model.length !== 0
          ) {
            return 'Введите не менее 8 символов без пробелов'
          }
          if (this.$v.form[field].$model.length > MAX_LETTERS_ORG) {
            return 'Максимальное количество знаков ' + MAX_LETTERS_ORG
          }
          if (!this.$v.form[field].serverValidated) {
            return 'Проверьте правильность заполнения поля'
          }
          break
        case 'phone':
          if (!this.$v.form[field].required && this.$v.$dirty) {
            return 'Укажите номер телефона'
          }
          if (!this.$v.form[field].serverValidated) {
            return 'Проверьте правильность заполнения поля'
          }
          break
        case 'purpose_of_use':
          if (
            !this.$v.form[field].required &&
            this.$v.$dirty &&
            !this.$v.form[field].$model
          ) {
            return 'Выберите цель использования'
          }
          break
        case 'number_of_employees':
        case 'number_of_employees_select':
          if (
            !this.$v.form[field].required &&
            this.$v.$dirty &&
            !this.$v.form[field].$model
          ) {
            return 'Обязательное поле'
          }
          break
        case 'direction_to_use_select':
        case 'user_role_select':
          if (
            !this.$v.form[field].required &&
            this.$v.$dirty &&
            !this.$v.form[field].$model
          ) {
            return 'Обязательное поле'
          }
          break
        case 'question':
        case 'message':
          if (!this.$v.form[field].required && this.$v.$dirty) {
            return EMPTY_FIELD_ERROR_MESSAGE
          }
          break
        case 'fio':
          if (!this.$v.form[field].required && this.$v.$dirty) {
            return 'Поле "Ф.И.О" не может быть пустым'
          }
          if (this.$v.form[field].$model.length > MAX_LETTERS_NAME) {
            return 'Максимальное количество знаков ' + MAX_LETTERS_NAME
          }
          if (!this.$v.form[field].serverValidated) {
            return 'Проверьте правильность заполнения поля'
          }
          break
        case 'city':
          if (!this.$v.form[field].required && this.$v.$dirty) {
            return EMPTY_FIELD_ERROR_MESSAGE
          }
          break
        case 'site':
          if (!this.$v.form[field].required && this.$v.$dirty) {
            return EMPTY_FIELD_ERROR_MESSAGE
          }
          break
        case 'typePartners':
          if (!this.$v.form[field].required && this.$v.$dirty) {
            return EMPTY_FIELD_ERROR_MESSAGE
          }
          break
        case 'promo':
          if (this.$v.form[field].$model.length > 10) {
            return 'Промокод может содержать не более 10 символов'
          }
          break
      }
    },
    __onServerErrorReceived(params) {
      if (params.invalidFields) {
        for (const field in params.invalidFields) {
          /**
           * dunno why, but storing values and errors messages in this.invalidValues breaks vuelidate,
           * so little dirty hack here
           */
          this.invalidValues[field].push(this.form[field])
          this.valuesServerErrors[this.form[field]] =
            params.invalidFields[field]
        }
      }
      this.$v.$touch()
      this.$emit('validate')
    },
    serverValidated(field) {
      return !this.invalidValues[field].includes(this.form[field])
    },
  },
}
