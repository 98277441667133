<template>
  <div class="wrapper">
    <main>
      <Nuxt />
    </main>
    <LazyHydrate when-visible>
      <LazyFooterDesktop />
    </LazyHydrate>
    <ExternalIntegrations />

    <DataCollection />
    <div class="popmechanic-disable-flag"
      :class="{ true: isAutotest }" />
  </div>
</template>
<script>
import LazyHydrate from "vue-lazy-hydration"
import { initPageData, isNoindexNofollow } from "@/helpers/pageData";
import EventBus from "@/mixins/EventBus";
import ExternalIntegrations from "@/components/Common/ExternalIntegrations/ExternalIntegrations.vue";
import DataCollection from "@/components/Common/DataCollection.vue";
import LazyFooterDesktop from "~/components/Desktop/Footer/Lazy/LazyFooterDesktop.vue";

export default {
  name: 'primary-desktop',
  components: {
    LazyFooterDesktop,
    DataCollection,
    ExternalIntegrations,
    LazyHydrate,
  },
  mixins: [EventBus],
  data() {
    return {
      isAutotest: false,
    }
  },
  head() {
    return {
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        { innerHTML: JSON.stringify(this.generateLdPlusJSON()), type: 'application/ld+json' }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://mts-link.ru' + this.$route.path,
        },
      ],
      meta: [
        ...isNoindexNofollow(this.$route.path) ? [{ name: 'robots', content: 'noindex, nofollow' }] : [],
      ],
    }
  },
  mounted() {
    initPageData();
    if (this.$route.query.autotest === '1') {
      this.isAutotest = true
    }
  },
  methods: {
    generateLdPlusJSON() {
      return {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "МТС Линк",
        "alternateName": "МТС Линк",
        "url": "https://mts-link.ru" + this.$route.path,
        "logo": "https://mts-link.ru/_nuxt/img/mts-link.svg"
      };
    },
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding: 0px 0 24px;
}

.base-content {
  position: relative;
  flex-grow: 1;
}
</style>
