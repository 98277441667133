<template>
  <a href="https://mts-link.ru/spec-offer/messenger/ " class="header-banner" target="_blank">
    <div class="header-banner__inner">
      <div class="header-banner__img header-banner__img--m">
        <img
          :src="require('~/assets/img/headline/headline-chats_1.svg')"
          width="98"
          height="50"
        />
      </div>
      <div class="header-banner__img header-banner__img--s">
        <img
          :src="require('~/assets/img/headline/headline_1.svg')"
          alt=""
          width="64"
          height="50"
        />
      </div>
      <div class="header-banner__img header-banner__img--m">
        <img
          :src="require('~/assets/img/headline/headline_2.svg')"
          alt=""
          width="98"
          height="50"
        />
      </div>
      <div class="header-banner__content">
        <div class="header-banner__content_text">
          Быстрый переход из&nbsp;Slack в&nbsp;МТС&nbsp;Линк Чаты
        </div>
        <VIconMTS
          :name="'mts/icon-mts--arrow-link'"
          width="24"
          height="24"
          fill="#1d2023"
          class="header-banner__content_icon"
        />
      </div>
      <div class="header-banner__img header-banner__img--m">
        <img
          :src="require('~/assets/img/headline/headline_3.svg')"
          alt=""
          width="98"
          height="50"
        />
      </div>
      <div class="header-banner__img header-banner__img--s">
        <img
          :src="require('~/assets/img/headline/headline_4.svg')"
          alt=""
          width="64"
          height="50"
        />
      </div>
      <div class="header-banner__img header-banner__img--m">
        <img
          :src="require('~/assets/img/headline/headline_2.svg')"
          alt=""
          width="98"
          height="50"
        />
      </div>
    </div>
  </a>
</template>
<script>
import VIconMTS from '~/components/Common/MTS/VIconMTS.vue'
export default {
  name: 'HeaderBannerDesktop',
  components: { VIconMTS },
}
</script>
<style lang="scss" scoped>
.header-banner {
  display: block;
  position: relative;
  top: 0;
  background: $color-lavander;
  z-index: 10000;
  max-height: 68px;
  text-decoration: none;
  font-family: $font-mts-wide;
  text-align: center;
  color: #1d2023;
  transition: 0.3s;

  &__inner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 9px;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    @include screen-up('lg') {
      padding-left: 10px;
      padding-right: 10px;
    }
    @include screen-down('lg') {
      padding-left: 40px;
      padding-right: 40px;
    }
    & > *:not(:last-child) {
      margin-right: 16px;
    }
  }
  &__img {
    flex: 0 1 auto;
    display: block;
    &:nth-child(odd) {
      @include screen-down('lg') {
        display: none;
      }
    }
    &:first-child,
    &:last-child {
      @include screen-down(1264px) {
        display: none;
      }
    }
  }
  &__content {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    &_icon {
      display: inline-block;
      margin-left: 16px;
    }
  }
}
</style>
