<template>
  <div class="social">
    <a
      v-for="(link, idx) in socialList"
      :key="idx"
      :href="link.href"
      class="social__links"
    >
      <VIconMTS
        :name="link.icon"
        :width="icon.width"
        :height="icon.height"
        :fill="icon.color"
        class="social__links-icon"
      />
    </a>
  </div>
</template>
<script>
import VIconMTS from '~/components/Common/MTS/VIconMTS.vue'

export default {
  name: 'SocialLinksBlock',
  components: { VIconMTS },
  props: {
    icon: {
      type: Object,
      default: () => {},
    },
    socialList: {
      type: Array,
      default: () => [
        {
          href: 'https://t.me/mts_link',
          icon: 'social/icon-mts--telegram',
        },
        {
          href: 'https://vk.com/mtslink',
          icon: 'social/icon-mts--vk',
        },
        {
          href: 'https://rutube.ru/channel/42339046/',
          icon: 'social/icon-mts--rutube',
        },
        {
          href: 'https://www.youtube.com/@mtslink',
          icon: 'social/icon-mts--youtube',
        },
      ],
    },
  },
}
</script>
<style lang="scss" scoped>
.social {
  &__links {
    display: inline-block;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
