import Cookies from 'js-cookie'

/**
 * Класс, содержащий инициализацию AB-тестов и хелперы для них
 *
 * В BPM поля для ab-тестов такие (название: name_поля):
 * "A/B Экран-фильтр участников": ab_meetings_filter
 * "A/B Вариант формы": ab_meetings_form_variant
 *
 * !!!README!!!:
 * Очень часто для анализа ab-тестов отправляются комментарии в BPM. Для этого там существуют поля, описанные выше.
 * В конкретные формы добавляются скрытые поля, которые возвращаются из функции getABTestHiddenFields,
 * и вполне вероятна ситуация, когда один и тот же компонент с формой будет и использоваться в AB-тесте, и нет.
 * Например: тестируем новый контент на главной, но только для новых пользователей. Значит для старых пользователей мы
 * комментарий вообще не отправляем. Для этого был изобретен костыль в виде поля isABTestEnabled, который emit-ится
 * по всем необходимым компонентам. Для примера выше: в компоненте Main.vue устанавливается isABTestEnabled = true
 * и при вызове одной из форм с главной это значение через eventBus "всплывает" в primary layout, оттуда передается
 * в компонент FormSidebar, а оттуда разлетается по необходимым формам. Этот функционал НЕ СЛЕДУЕТ УДАЛЯТЬ после отключения
 * очередного AB-теста – тестируется что-то часто и каждый раз восстанавливать этот функционал очень трудозатратно.
 * Достаточно просто удалить установку isABTestEnabled = true и вызов функции, отвечающий за текущий AB-тест.
 *
 * isABTestEnabled устанавливается в components/Page/Main/MainMixin.js
 */
export default class ABTests {

  static MTS_OLD_USER_COOKIE_NAME = 'mtsOldUser'
  static DISABLE_OLD_USERS = false                      // не выполнять А/Б-тест для старых юзеров
  static EXPERIMENT_COOKIE = 'ab_meetings_form_variant'   // код cookie текущего экспериметнта
  static COOKIE_DAYS = 30                               // срок жизни куки (в днях)

  /**
   * Доступные поля для отправки информации об AB-тестах
   * @type {string[]}
   */
  static availableFields = [
    'ab_meetings_form_variant',    // поле в BPM "A/B Вариант формы"
    // 'ab_meetings_filter',       // поле в BPM "A/B Экран-фильтр участников"
  ]

  /**
   * Значения, которые будут записаны в скрытые поля в зависимости от вариантов
   * Пример:
   * 'ab_meetings_filter': {
   *   '1': 'А/Б-тест 10-2022. Экран-фильтр участников',
   *   '2': 'А/Б-тест 10-2022. Не видел экран-фильтр участников',
   * },
   * @type {{}}
   */
  static hiddenFieldsValues = {
    ab_meetings_form_variant: {
      0: '',
      1: 'AB-test-251124-no-job', // в коде форм к этому значению добавляется суффикс "-org" или "-part"
      2: 'AB-test-251124-yes-job'
    },
    /* ab_meetings_filter: {
      0: '',
      1: '',
      2: ''
    }, */
  }

  /**
   * Объект, содержащий соответствия полей кукам
   * @type {{}}
   */
  static cookiesNames = {
    ab_meetings_form_variant: 'ab-test-251124-wr-1488'
    // ab_meetings_filter: 'ab-test-151024-wr-1461'
  }

  static getABFormSelectorVariant() {
    return '0'; // все А/Б-тесты отключены

    // eslint-disable-next-line no-unreachable
    if (ABTests.isUserRobot()) {
      return '0'
    }

    const cookieName = ABTests.cookiesNames[ABTests.EXPERIMENT_COOKIE]
    let abVariant = Cookies.get(cookieName);

    // если кука А/Б теста не установлена и юзер ранее уже заходил на сайт - не выполняем А/Б тест
    if (ABTests.DISABLE_OLD_USERS && !abVariant && ABTests.isOldUser()) {
      return '0'
    }

    if (!abVariant || !['1', '2'].includes(abVariant)) {
      const randValue = ABTests.getRandomInt(100).toString();
      abVariant = randValue <= 50 ? '1' : '2'; // 1й вариант для 50% пользователей, 2й для оставшихся 50%
      Cookies.set(cookieName, abVariant, { expires: ABTests.COOKIE_DAYS })
    }

    return abVariant
  }

  static getABFirstScreenVariantSsr(req, res) {
    if (ABTests.isUserRobot(req.headers['user-agent'])) {
      return '0'
    }

    const cookieName = ABTests.cookiesNames[ABTests.EXPERIMENT_COOKIE]
    let abVariant = req.cookies[cookieName]

    if (ABTests.DISABLE_OLD_USERS && !abVariant && ABTests.isOldUser({ req })) {
      return '0'
    }

    if (!abVariant || !['1', '2'].includes(abVariant)) {
      const randValue = ABTests.getRandomInt(100).toString();
      abVariant = randValue <= 50 ? '1' : '2'; // 1й вариант для 50% пользователей, 2й для оставшихся 50%
      res.cookie(
        cookieName,
        abVariant,
        { 'maxAge': ABTests.COOKIE_DAYS * 24 * 60 * 60 * 1000 } // 30 days
      );
    }

    return abVariant
  }

  /**
   * Возвращает объект, содержащий значения для скрытых полей
   *
   * @param code - код конкретного поля (если не задан, то все поля)
   * @param suffix - суффикс для значения поля (если нужен специфичный для формы)
   * @returns {{}}
   */
  static getABTestHiddenFields(code = null, suffix = '') {
    const result = {}
    // Отключить передачу данных в поля АБ теста
     return result;

    // eslint-disable-next-line no-unreachable
    if (!code || code === '') {
      ABTests.availableFields.forEach((fieldCode) => {
        result[fieldCode] = ABTests.getCookieValue(fieldCode, suffix);
      })
      // eslint-disable-next-line no-unreachable
    } else {
      result[code] = ABTests.getCookieValue(code, suffix);
    }
    return result
  }

  static getCookieValue(code, suffix = '') {
    const abVariant = Cookies.get(ABTests.cookiesNames[code])
    if (abVariant) {
      const value = ABTests.hiddenFieldsValues[code][abVariant];
      return (value !== '' ? value + suffix : '');
    }
  }

  /**
   * Определяет, является ли текущий пользователь поисковым роботом
   * @returns {boolean}
   */
  static isUserRobot(userAgent = null) {
    const searchBotsUserAgents = [
      'YandexBot',
      'YandexAccessibilityBot',
      'YandexMobileBot',
      'YandexDirectDyn',
      'YandexScreenshotBot',
      'YandexImages',
      'YandexVideo',
      'YandexVideoParser',
      'YandexMedia',
      'YandexBlogs',
      'YandexFavicons',
      'YandexWebmaster',
      'YandexPagechecker',
      'YandexImageResizer',
      'YandexAdNet',
      'YandexDirect',
      'YaDirectFetcher',
      'YandexCalendar',
      'YandexSitelinks',
      'YandexMetrika',
      'YandexNews',
      'YandexNewslinks',
      'YandexCatalog',
      'YandexAntivirus',
      'YandexMarket',
      'YandexVertis',
      'YandexForDomain',
      'YandexSpravBot',
      'YandexSearchShop',
      'YandexMedianaBot',
      'YandexOntoDB',
      'YandexOntoDBAPI',
      'Googlebot',
      'Googlebot-Image',
      'Mediapartners-Google',
      'AdsBot-Google',
      'Mail.RU_Bot',
      'bingbot',
      'Accoona',
      'ia_archiver',
      'Ask Jeeves',
      'OmniExplorer_Bot',
      'W3C_Validator',
      'WebAlta',
      'YahooFeedSeeker',
      'Yahoo!',
      'Ezooms',
      'Tourlentabot',
      'MJ12bot',
      'AhrefsBot',
      'SearchBot',
      'SiteStatus',
      'Nigma.ru',
      'Baiduspider',
      'Statsbot',
      'SISTRIX',
      'AcoonBot',
      'findlinks',
      'proximic',
      'OpenindexSpider',
      'statdom.ru',
      'Exabot',
      'Spider',
      'SeznamBot',
      'oBot',
      'C-T bot',
      'Updownerbot',
      'Snoopy',
      'heritrix',
      'Yeti',
      'DomainVader',
      'DCPbot',
      'PaperLiBot'
    ]

    let isRobot = false

    if (userAgent === null) {
      userAgent = navigator.userAgent;
    }

    searchBotsUserAgents.forEach((agent) => {
      if (userAgent.includes(agent)) {
        isRobot = true
      }
    })

    return isRobot
  }

  /**
   * Определяет, перешёл ли пользователь на сайт впервые
   * (по наличию установленной куки с датой первого посещения сайта)
   * @returns {boolean}
   */
  static isOldUser(ctx = null) {
    let isOld = false;

    if (ctx === null) {
      isOld = Cookies.get(ABTests.MTS_OLD_USER_COOKIE_NAME);
    } else {
      isOld = ctx.req.cookies[ABTests.MTS_OLD_USER_COOKIE_NAME];
    }

    return typeof isOld !== 'undefined' && !!isOld && isOld !== 'false';
  }

  /**
   * Генерирует случайное число от 1 до max включительно
   * @param max
   * @returns {number}
   */
  static getRandomInt(max) {
    return Math.floor(Math.random() * max) + 1
  }
}
